.menu-main {
  position: relative;
}
.menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(0.7 * var(--nav-scale));
  height: calc(0.7 * var(--nav-scale));
  background-color: var(--color-primary);
  transform: rotate(45deg) translateX(0) translateY(-70.7%);
  cursor: pointer;
  z-index: var(--z-index-nav-toggle);
  box-shadow: 0px 1px 6px 1px;
  &:after {
    content: 'Menu';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(2px);
  }
}
.menu-active {
  box-shadow: 0px 1px 6px 1px;
  transform: translateX(-100%);
}
.nav-block {
  position: relative;
  /* width: calc(0.5 * var(--nav-scale)); */
  padding: calc(0.1 * var(--nav-scale));
  color: var(--color-icon);
  outline: none;
  text-decoration: none;
  &:after {
    transition: var(--anim-delay) opacity ease; /* FIX ON MOBILE, BOOTSTRAP XS HALVE SIZES */
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: rgba(0, 0, 0, 0.5);
  }
  &:hover,
  &:focus {
    cursor: pointer;
    &:after {
      opacity: 1;
    }
  }
}
.menu-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  font-weight: bold;
  & > nav {
    top: 0;
    position: absolute;
    display: inline-block;
    height: calc(0.5 * var(--nav-scale));
    padding: 0 calc(0.25 * var(--nav-scale));
    background-color: var(--color-primary);
    transition: var(--anim-delay) transform ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: var(--z-index-nav);
  }
}
