.postlist li {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background-color: var(--color-links);
    transition: transform var(--anim-delay) ease;
    transform: scaleX(0) translate(5px, 5px);
  }
  &:hover:before {
    transform: scaleX(1) translate(5px, 5px);
  }
}
@media (width >= 1050px) {
  .postlist ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (width < 1050px) {
  .postlist ul {
    grid-template-columns: repeat(2, 1fr);
    & li:nth-last-child(-n + 1) {
      display: none;
    }
  }
}
@media (width < 600px) {
  .postlist ul {
    grid-template-columns: 1fr;
    /* & li:nth-last-child(-n+3) {
      display: none;
    } */
  }
}
